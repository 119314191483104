import React from 'react'
import i18n from '../../../config/i18n'
import { renderBasicModal } from '../../Modal/fabric'
import { notificationSuccess } from '../../../utils/notification'
import DeleteAccount, { DeleteAccountProps } from '.'

export const renderCloseAccountModal = (formOptions: DeleteAccountProps, dialogOptions = {}) => {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'px-2 pt-2 pb-4 max-w-sm'}>
      <div className={'mb-3'}>
        <h1 className={'text-2xl mb-2 font-bold'}>{t('close-account.modal.title')}</h1>
        <p className={'text-gray-700 text-lg'}>{t('close-account.modal.subtitle')}</p>
      </div>

      <DeleteAccount
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('close-account.success'), 500)
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderBasicModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}
