import React from 'react'
import i18n from '../../../config/i18n'
import { renderFullScreenModal } from '../../Modal/fabric'
import { notificationSuccess } from '../../../utils/notification'
import UpdateTutorProfile, { UpdateTutorProfileType } from '.'

export function renderUpdateTutorProfileModal(
  formOptions: UpdateTutorProfileType,
  dialogOptions = {},
) {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'max-w-xl mx-auto px-6 sm:px-0'}>
      <div className={'mb-6 mt-32'}>
        <h1 className={'text-4xl mb-3 font-bold'}>{t('update-tutor.modal.title')}</h1>
        <p className={'text-gray-700 text-lg'}>{t('update-tutor.modal.subtitle')}</p>
      </div>

      <UpdateTutorProfile
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('update-tutor.success'), 500)
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderFullScreenModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}
