import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import LoadingButton from '../../Button/LoadingButton'
import { SolidRow, SIZES, SolidFloatingLabelInput, SolidError } from '../../Input/solid'
import api from '../../../apis'
import useAuth from '../../../state/auth/useAuth'

export type DeleteAccountProps = {
  onSuccess: () => void
}

const DeleteAccount = ({ onSuccess }: DeleteAccountProps) => {
  const { t } = useTranslation('forms')

  const { reloadData } = useAuth()

  const [isSubmitting, setSubmitting] = useState(false)
  const [confirm, setConfirm] = useState('')
  const [confirmError, setConfirmError] = useState('')

  const compareString = t('close-account.confirm.compareWith')

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postCloseAccount()
      await reloadData()
      setSubmitting(false)
      onSuccess()
    } catch (error) {
      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <SolidRow>
        <SolidFloatingLabelInput
          label={t('close-account.confirm.label')}
          size={SIZES.LARGE}
          value={confirm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirm(e.target.value)
            setConfirmError('')
          }}
          isInvalid={confirmError.length > 0}
          disabled={isSubmitting}
          required={true}
          placeholder={t('close-account.confirm.placeholder')}
        />

        {confirmError && <SolidError>{confirmError}</SolidError>}
      </SolidRow>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        disabled={confirm !== compareString || isSubmitting}
        className={'btn btn-danger-outline btn-lg w-full'}
        label={t('close-account.submit.label')}
      />
    </form>
  )
}

DeleteAccount.propTypes = {
  onSuccess: PropTypes.func,
}

DeleteAccount.defaultProps = {
  onSuccess: () => null,
}

export default DeleteAccount
