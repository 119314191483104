import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import AvatarImageCropper from './libs/react-avatar-image-cropper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../../apis'
import useAuth from '../../../state/auth/useAuth'
import classnames from 'classnames'
import LoadingButton from '../../Button/LoadingButton'

export type UpdateAvatarType = {
  onSuccess: () => void
  onDeleteSuccess: () => void
}

const UpdateAvatar = ({ onSuccess, onDeleteSuccess }: UpdateAvatarType) => {
  const { t } = useTranslation('forms')
  const { user, reloadData } = useAuth()

  const maxsize = 1024 * 1024 * 10 // 10MB
  const width = '402px' // image width for submission
  const height = '402px' // image height for submission

  const isAvatarPresent = typeof user.getAvatar(400) !== 'undefined'

  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const backgroundImage =
    isAvatarPresent && isUploading === false ? `url("${user.getAvatar(400)}")` : 'none'

  const applyImage = async (file: File) => {
    setIsUploading(true)

    let data = new FormData()
    data.append('file', file)

    try {
      await api.auth.postAvatar(data)
      setIsUploading(false)
      onSuccess()
    } catch (error) {
      error.handleDefault()
    }
  }

  const deleteAvatar = async () => {
    setIsDeleting(true)

    try {
      await api.auth.deleteAvatar()
      await reloadData()
      setIsDeleting(false)
      onDeleteSuccess()
    } catch (error) {
      error.handleDefault()
    }
  }

  return (
    <div className={'w-full'}>
      <div
        style={{ width: width, height: height, backgroundImage: backgroundImage }}
        className={'border border-gray-200 relative'}
      >
        {isUploading ? (
          <div className={'flex items-center justify-center h-full'}>
            <div className={'flex flex-col items-center justify-center'}>
              <FontAwesomeIcon
                className={'text-5xl mb-2 text-gray-400'}
                spin={true}
                icon={['fad', 'spinner-third']}
              />
              <div className={'text-sm text-gray-400 text-thin'}>
                {t('upload-avatar.loadingLabel')}
              </div>
            </div>
          </div>
        ) : (
          <AvatarImageCropper
            apply={applyImage}
            maxsize={maxsize}
            isBack={isAvatarPresent}
            text={
              <span
                className={classnames('text-sm text-thin', {
                  'text-gray-400': isAvatarPresent === false,
                  'text-white': isAvatarPresent,
                })}
              >
                {t('upload-avatar.placeholderLabel')}
              </span>
            }
            icon={
              <FontAwesomeIcon className={'text-5xl mb-2 text-gray-200'} icon={['fal', 'camera']} />
            }
            sliderConStyle={{ top: 'unset', bottom: 0 }}
            applyBtnStyle={{
              color: '#FFF',
              backgroundColor: '#84CC16', //bg-lime-500
              borderColor: '#84CC16', // bg-lime-500
            }}
          />
        )}
      </div>

      {isAvatarPresent && isUploading === false && (
        <LoadingButton
          submitting={isDeleting}
          onClick={deleteAvatar}
          className={'w-full btn btn-danger-outline mt-4'}
        >
          {t('upload-avatar.deleteAvatarButton')}
        </LoadingButton>
      )}
    </div>
  )
}

UpdateAvatar.propTypes = {
  onSuccess: PropTypes.func,
}

UpdateAvatar.defaultProps = {
  onSuccess: () => null,
}

export default UpdateAvatar
