import React from 'react'
import i18n from '../../../config/i18n'
import { renderFullScreenModal } from '../../Modal/fabric'
import { notificationSuccess } from '../../../utils/notification'
import ChangePassword, { ChangePasswordProps } from '.'

export const renderChangePasswordModal = (formOptions: ChangePasswordProps, dialogOptions = {}) => {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'max-w-lg mx-auto px-4'}>
      <div className={'mb-6 mt-32'}>
        <h1 className={'text-4xl mb-3 font-bold'}>{t('change-password.modal.title')}</h1>
        <p className={'text-gray-700 text-lg'}>{t('change-password.modal.subtitle')}</p>
      </div>

      <ChangePassword
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('change-password.success'), 500)
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderFullScreenModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}
