export const ALLOWED_LINKS = [
  {
    id: 'qtc',
    placeholder: 'Qualified Tutors’ Community URL',
    label: 'Qualified Tutors’ Community',
  },
  {
    id: 'tta',
    placeholder: 'The Tutors’ Association URL',
    label: 'The Tutors’ Association',
  },
  {
    id: 'my-tutor',
    placeholder: 'MyTutor URL',
    label: 'MyTutor',
  },
  {
    id: 'tutor-bloc',
    placeholder: 'Tutorbloc URL',
    label: 'Tutorbloc',
  },
  {
    id: 'tutor-house',
    placeholder: 'Tutor House URL',
    label: 'Tutor House',
  },
  {
    id: 'tutorful',
    placeholder: 'Tutorful URL',
    label: 'Tutorful',
  },
  {
    id: 'linkedin',
    placeholder: 'Linkedin URL',
    label: 'Linkedin',
  },
  {
    id: 'custom',
    placeholder: 'Custom URL',
    label: null, // hostname
  },
  {
    id: 'custom2',
    placeholder: 'Custom URL',
    label: null, // hostname
  },
  {
    id: 'custom3',
    placeholder: 'Custom URL',
    label: null, // hostname
  },
]
