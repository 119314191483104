import React from 'react'
import i18n from '../../../config/i18n'
import { notificationSuccess } from '../../../utils/notification'
import { renderBasicModal } from '../../Modal/fabric'
import UpdateAvatar, { UpdateAvatarType } from '.'

export const renderUploadAvatarModal = (formOptions: UpdateAvatarType, dialogOptions = {}) => {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'px-2 pt-2 pb-4'}>
      <div className={'mb-3'}>
        <h1 className={'text-2xl mb-2 font-bold'}>{t('upload-avatar.modal.title')}</h1>
        <p className={'text-gray-700 text-lg'}>{t('upload-avatar.modal.subtitle')}</p>
      </div>

      <UpdateAvatar
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('upload-avatar.success'), 500)
        }}
        onDeleteSuccess={() => {
          modal.current.close()
          notificationSuccess(t('upload-avatar.deleteSuccess'), 500)
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderBasicModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}
