import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  SolidRow,
  SolidError,
  SIZES,
  SolidFloatingLabelInput,
  SolidFloatingLabelTextArea,
} from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import useAuth from '../../../state/auth/useAuth'
import WarningAlert from '../../Alert/WarningAlert'

export type UpdatePersonalProfileType = {
  onSuccess: () => void
}

const UpdatePersonalProfile = ({ onSuccess }: UpdatePersonalProfileType) => {
  const { t } = useTranslation('forms')

  const { user, reloadData, logout } = useAuth()

  const [isSubmitting, setSubmitting] = useState(false)

  const [firstName, setFirstName] = useState(user.first_name)
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(user.last_name)
  const [lastNameError, setLastNameError] = useState('')
  const [username, setUsername] = useState(user.username)
  const [usernameError, setUsernameError] = useState('')
  const [email, setEmail] = useState(user.email)
  const [emailError, setEmailError] = useState('')
  const [bio, setBio] = useState(user.bio || '')
  const [bioError, setBioError] = useState('')

  const isEmailChanged = email !== user.email
  const isEmailVerified = user.email_verified

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.patchUpdateProfile({
        firstName,
        lastName,
        email,
        username,
        bio,
      })

      if (isEmailChanged) {
        await logout()
      } else {
        await reloadData()
      }

      setSubmitting(false)
      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        return error.handle422({
          firstName: setFirstNameError,
          lastName: setLastNameError,
          email: setEmailError,
          username: setUsernameError,
          bio: setBioError,
        })
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={'flex flex-col sm:flex-row'}>
        <SolidRow className={'sm:w-1/2 sm:mr-2'}>
          <SolidFloatingLabelInput
            label={t('update-profile.first-name.label')}
            size={SIZES.LARGE}
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value)
              setFirstNameError('')
            }}
            isInvalid={firstNameError.length > 0}
            disabled={isSubmitting}
            required={true}
            placeholder={t('update-profile.first-name.placeholder')}
          />

          {firstNameError && <SolidError>{firstNameError}</SolidError>}
        </SolidRow>

        <SolidRow className={'sm:w-1/2 sm:ml-2'}>
          <SolidFloatingLabelInput
            label={t('update-profile.second-name.label')}
            size={SIZES.LARGE}
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value)
              setLastNameError('')
            }}
            isInvalid={lastNameError.length > 0}
            disabled={isSubmitting}
            required={true}
            placeholder={t('update-profile.second-name.placeholder')}
          />

          {lastNameError && <SolidError>{lastNameError}</SolidError>}
        </SolidRow>
      </div>

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('update-profile.username.label')}
          size={SIZES.LARGE}
          value={username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(e.target.value)
            setUsernameError('')
          }}
          isInvalid={usernameError.length > 0}
          disabled={isSubmitting}
          required={true}
          placeholder={t('update-profile.username.placeholder')}
        />

        {usernameError && <SolidError>{usernameError}</SolidError>}
      </SolidRow>

      <SolidRow>
        <SolidFloatingLabelTextArea
          label={t('update-profile.bio.label')}
          size={SIZES.LARGE}
          value={bio}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setBio(e.target.value)
            setBioError('')
          }}
          isInvalid={bioError.length > 0}
          disabled={isSubmitting}
          placeholder={t('update-profile.bio.placeholder')}
        />

        {bioError && <SolidError>{bioError}</SolidError>}
      </SolidRow>

      <SolidRow>
        <SolidFloatingLabelInput
          label={
            isEmailVerified
              ? t('update-profile.email.label')
              : t('update-profile.email.labelNotVerified')
          }
          size={SIZES.LARGE}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          isInvalid={emailError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={'email'}
          autoComplete={'off'}
          placeholder={t('update-profile.email.placeholder')}
        />

        {emailError && <SolidError>{emailError}</SolidError>}
      </SolidRow>

      {isEmailChanged && (
        <WarningAlert className={'mb-4 animate-fadeIn'}>
          <b>{t('update-profile.alerts.emailChanged.prefix')}: </b>
          {t('update-profile.alerts.emailChanged.message')}
        </WarningAlert>
      )}

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('update-profile.submit.label')}
      />
    </form>
  )
}

UpdatePersonalProfile.propTypes = {
  onSuccess: PropTypes.func,
}

UpdatePersonalProfile.defaultProps = {
  onSuccess: () => null,
}

export default UpdatePersonalProfile
