import React from 'react'
import { NakedLayout, Redirect, SplashPage } from '../../components/Layout'
import GoBackButton from './_components/GoBackButton'
import Title from './_components/Title'
import Option from './_components/Option'
import OptionLabel from './_components/OptionLabel'
import OptionLabelValue from './_components/OptionLabelValue'
import OptionHeader from './_components/OptionHeader'
import OptionIcon from './_components/OptionIcon'
import OptionContainer from './_components/OptionContainer'
import { navigate } from 'gatsby'
import ROUTES from '../../config/routes'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'
import useAuth from '../../state/auth/useAuth'
import Avatar from 'react-avatar'
import { renderChangePasswordModal } from '../../components/Form/ChangePassword/fabric'
import { renderUploadAvatarModal } from '../../components/Form/UpdateAvatar/fabric'
import { renderUpdatePersonalProfileModal } from '../../components/Form/UpdatePersonalProfile/fabric'
import { renderUpdateTutorProfileModal } from '../../components/Form/UpdateTutorProfile/fabric'
import { renderCloseAccountModal } from '../../components/Form/CloseAccount/fabric'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const UserAvatar = ({ user }) => {
  return (
    <Avatar
      className={'cursor-pointer'}
      round
      fgColor={'white'}
      color={'black'}
      src={user.getAvatar(50)}
      name={user.getFullName()}
      size="35"
    />
  )
}

const SettingsPage = () => {
  const { t } = useTranslation('pages')

  const { isLoaded, isGuest, user, logout } = useAuth()

  if (isLoaded === false) {
    return <SplashPage />
  }

  if (isGuest) {
    return <Redirect to={ROUTES.home} />
  }

  return (
    <NakedLayout isHeightFull bodyClassName={'bg-gray-100'}>
      <main className="relative">
        <div className={'max-w-lg mx-auto'}>
          <div className="flex flex-col w-full">
            <div className={'px-4 mt-5 lg:mt-8 flex items-center justify-between relative'}>
              <GoBackButton onClick={() => navigate(ROUTES.goBack)} />

              <Title>{user.getHiddenLastName()}</Title>

              <button onClick={() => renderUploadAvatarModal()}>
                <UserAvatar user={user} />

                <div className={'absolute right-0 top-0'}>
                  <div
                    className={
                      'bg-white rounded-full w-5 h-5 mt-5 mr-3 flex items-center justify-center cursor-pointer'
                    }
                  >
                    <FontAwesomeIcon className={'text-xs text-black'} icon={['fas', 'camera']} />
                  </div>
                </div>
              </button>
            </div>

            <div className={'mt-8 px-4'}>
              <OptionContainer>
                <Option onClick={() => navigate(ROUTES.help)}>
                  <OptionIcon icon={['fas', 'question-circle']} />
                  <OptionLabel>{t('settings.options.help')}</OptionLabel>
                </Option>
              </OptionContainer>

              <OptionHeader>{t('settings.headers.profile')}</OptionHeader>

              <OptionContainer>
                <Option onClick={() => renderUpdatePersonalProfileModal()}>
                  <OptionIcon icon={['fas', 'user']} />
                  <OptionLabel>{t('settings.options.personalProfile')}</OptionLabel>
                </Option>

                <Option onClick={() => renderUpdateTutorProfileModal()}>
                  <OptionIcon icon={['fas', 'briefcase']} />
                  <OptionLabel>{t('settings.options.tutorProfile')}</OptionLabel>
                </Option>
              </OptionContainer>

              <OptionHeader>{t('settings.headers.location')}</OptionHeader>

              <OptionContainer>
                <Option>
                  <OptionIcon icon={['fas', 'language']} />
                  <OptionLabel>
                    <span>{t('settings.options.language')}</span>
                    <OptionLabelValue>English (United Kingdom)</OptionLabelValue>
                  </OptionLabel>
                </Option>

                <Option>
                  <OptionIcon icon={['fas', 'globe-europe']} />
                  <OptionLabel>
                    <span>{t('settings.options.timezone')}</span>
                    <OptionLabelValue>
                      {Intl.DateTimeFormat().resolvedOptions().timeZone}
                    </OptionLabelValue>
                  </OptionLabel>
                </Option>
              </OptionContainer>

              <OptionHeader>{t('settings.headers.security')}</OptionHeader>

              <OptionContainer>
                <Option onClick={() => renderChangePasswordModal()}>
                  <OptionIcon icon={['fas', 'lock-alt']} />
                  <OptionLabel>{t('settings.options.changePassword')}</OptionLabel>
                </Option>

                <Option>
                  <OptionIcon icon={['fas', 'shield-alt']} />
                  <OptionLabel>{t('settings.options.privacy')}</OptionLabel>
                </Option>
              </OptionContainer>

              <OptionContainer>
                <Option onClick={() => renderCloseAccountModal()}>
                  <OptionIcon
                    icon={['fas', 'heart-broken']}
                    className={'text-left text-xl text-red-500'}
                  />
                  <OptionLabel>{t('settings.options.closeAccount')}</OptionLabel>
                </Option>

                <Option onClick={logout}>
                  <OptionIcon
                    icon={['fas', 'sign-out-alt']}
                    className={'text-left text-xl text-red-500'}
                  />
                  <OptionLabel>{t('settings.options.logout')}</OptionLabel>
                </Option>
              </OptionContainer>
            </div>
          </div>
        </div>

        <Footer className={'max-w-7xl px-2 border-t border-gray-200'} />
      </main>
    </NakedLayout>
  )
}

export default SettingsPage
