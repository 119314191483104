import React, { useState, useEffect } from 'react'
import {
  SolidRow,
  SolidError,
  SIZES,
  SolidFloatingLabelInput,
  SolidCheckbox,
  SolidCheckboxLabel,
} from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import PropTypes from 'prop-types'
import useAuth from '../../../state/auth/useAuth'
import { ALLOWED_LINKS } from './constants'

export type UpdateTutorProfileType = {
  onSuccess: () => void
}

const UpdateTutorProfile = ({ onSuccess }: UpdateTutorProfileType) => {
  const { t } = useTranslation('forms')

  const { user, reloadData } = useAuth()

  const [isSubmitting, setSubmitting] = useState(false)

  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})

  const [appearInSearch, setAppearInSearch] = useState(user.is_browsable)

  useEffect(() => {
    const formattedLinks = {}

    for (const link of user.links) {
      formattedLinks[link.id] = link.link
    }

    setValues(formattedLinks)
  }, [user])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.putUpdateLinks(values, appearInSearch)

      await reloadData()

      setSubmitting(false)

      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        const returnedErrors = {}

        error.response.data.forEach(({ field, message }) => {
          returnedErrors[field] = message
        })

        return setErrors(returnedErrors)
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={'flex justify-between items-center mb-5'}>
        <label>
          <SolidCheckbox
            checked={appearInSearch}
            size={SIZES.LARGE}
            onChange={() => setAppearInSearch(!appearInSearch)}
          />

          <SolidCheckboxLabel>Appear in search results as a tutor</SolidCheckboxLabel>
        </label>
      </div>

      {ALLOWED_LINKS.map(({ id, placeholder }) => (
        <SolidRow key={id}>
          <SolidFloatingLabelInput
            label={placeholder}
            size={SIZES.LARGE}
            value={values[id] || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValues({ ...values, [id]: e.target.value })
              setErrors({ ...errors, [id]: '' })
            }}
            isInvalid={errors[id] && errors[id].length > 0}
            disabled={isSubmitting}
            required={false}
            placeholder={placeholder}
          />

          {errors[id] && <SolidError>{errors[id]}</SolidError>}
        </SolidRow>
      ))}

      <LoadingButton
        type={'submit'}
        disabled={isSubmitting}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full mt-2 mb-20'}
        label={t('update-tutor.submit.label')}
      />
    </form>
  )
}

UpdateTutorProfile.propTypes = {
  onSuccess: PropTypes.func,
}

UpdateTutorProfile.defaultProps = {
  onSuccess: () => null,
}

export default UpdateTutorProfile
