import React from 'react'

const Option = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        'bg-white rounded-xl w-full py-5 px-6 flex items-center font-bold tracking-tight focus:outline-none hover:bg-gray-100 transition-colors duration-200'
      }
    >
      {children}
    </button>
  )
}

export default Option
