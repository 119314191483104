import React from 'react'
import i18n from '../../../config/i18n'
import { renderFullScreenModal } from '../../Modal/fabric'
import { notificationSuccess } from '../../../utils/notification'
import UpdatePersonalProfile, { UpdatePersonalProfileType } from '.'

export function renderUpdatePersonalProfileModal(
  formOptions: UpdatePersonalProfileType,
  dialogOptions = {},
) {
  const t = i18n.getFixedT(null, 'forms')

  const html = (
    <div className={'max-w-xl mx-auto px-6 sm:px-0'}>
      <div className={'mb-8 mt-32'}>
        <h1 className={'text-4xl font-bold'}>{t('update-profile.modal.title')}</h1>
      </div>

      <UpdatePersonalProfile
        onSuccess={() => {
          modal.current.close()
          notificationSuccess(t('update-profile.success'), 500)
        }}
        {...formOptions}
      />
    </div>
  )

  const modal = renderFullScreenModal({
    ...dialogOptions,
    html: html,
    modalProps: {
      focusTrapped: false,
    },
  })

  return modal
}
